import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormHelperText,
  CardContent,
  TextField,
  Typography,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  CardHeader,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Alert } from '@material-ui/lab';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Button1 from 'src/components/Button/CustomButton';
import theme from 'src/utils/ButtonTheme';
import { ThemeProvider } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,

    //paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 20,
    paddingTop: 40
  },
  cardContent: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
    //minHeight: 400
  },
  currentMethodIcon: {
    height: 100,
    width: 100,

    avatar: {
      height: 100,
      width: 100
    }
  }
}));

const Change_Password = ({ className, open, ...rest }) => {
  const classes = useStyles();
  const { confirm_forgot_password } = useAuth();
  const { forgot_password } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const [error, setError] = useState();

  return (
    <Formik
      initialValues={{
        current_password: '',
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={Yup.object().shape({
        current_password: Yup.string().required('Required'),
        password: Yup.string()

          .max(255)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          )
          .required('Required'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
    

          try {
            let response;
            const payload = {
              current_password: values.current_password,
              new_password: values.password
            };

            if (process.env.REACT_APP_ENV === 'test') {
              response = await axios.post(
                'https://auth.rovelabs.com/test/resetPassword',
                payload
              );
       
              open();
            } else {
              response = await axios.post(
                'https://auth.rovelabs.com/prod/resetPassword',
                payload
              );
          
              open();
            }
       
            if (response.data.message === 'success') {
              enqueueSnackbar('Password updated', {
                variant: 'success'
              });
            }
          } catch (err) {
        
            setError(err.response);
          }

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
      
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Page className={classes.root} title="Forgot Password">
            <Box display="flex" justifyContent="center"></Box>
            <Card style={{ backgroundColor: '#fafafa' }}>
            <Box style={{ backgroundColor: '#ffffff' }} p={0}>
              <CardHeader title="Reset Password" />
              </Box>
              <Divider />
          <Box style={{backgroundColor:'#fafafa'}}>

          <CardContent className={classes.cardContent}>
              <Grid container spaceing={5}>
                    <Grid item xs={3} md={3} lg={3}>
                      <Box mt={3}>
                        <Typography variant="body2" color="textSecondary">
                        Current Password
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9}>
                    <TextField
                  error={Boolean(
                    touched.current_password && errors.current_password
                  )}
                  fullWidth
                  size={'small'}
                  helperText={
                    touched.current_password && errors.current_password
                  }
                  margin="normal"
                  name="current_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  autoComplete="off"
                  value={values.current_password}
                  variant="outlined"
                />
                    </Grid>
                  </Grid>
                  <Box mt={1} />
                  <Grid container spaceing={5}>
                    <Grid item xs={3} md={3} lg={3}>
                      <Box mt={0.5}>
                        <Typography variant="body2" color="textSecondary">
                        New Password
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9}>
                    <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  name="password"
                  autoComplete="new-password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  size={'small'}
                  variant="outlined"
                />
                    </Grid>
                  </Grid>
                  <Box mt={2} />

                  <Grid container spaceing={5}>
                    <Grid item xs={3} md={3} lg={3}>
                      <Box mt={0.5}>
                        <Typography variant="body2" color="textSecondary">
                        New Password Confirmation
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9}>
                    <TextField
                  error={Boolean(
                    touched.passwordConfirm && errors.passwordConfirm
                  )}
                  fullWidth
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                  size={'small'}
                  name="passwordConfirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  autoComplete="off"
                  value={values.passwordConfirm}
                  variant="outlined"
                />

                    </Grid>
                  </Grid>

             
               
              
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      <Box mt={2}>
                        <Alert severity="info">
                          <div>{errors.submit}</div>
                        </Alert>
                      </Box>
                    </FormHelperText>
                  </Box>
                )}

                {error && (
                  <Box mt={2}>
                    <Alert severity="info">
                      <div>{error.data.message}</div>
                    </Alert>
                  </Box>
                )}
                
               
              </CardContent>
             <Box mt={2}/>
          </Box>
          <Divider/>
          <Box style={{ backgroundColor: '#ffffff' }}>
                <Divider />
                <Box display="flex">
                  <Box flexGrow={1} />
                  <ThemeProvider theme={theme}>
                  <Box
                      px={3}
                      py={1.5}
                      style={{ backgroundColor: '#ffffff', display: 'flex' }}
                    >
                      <Button1
                        size="medium"
                        color="grey"
                        onClick={open}
                        variant="outlined"
                        label={'Cancel'}
                      />
                      <Box mr={1.5} />                      <Button1
                        size="medium"
                        color="blue"
                        type="submit"
                        loading={isSubmitting}
                        variant="contained"
                        //  startIcon={'ic:round-add'}
                        label={'Submit'}
                      />
                    </Box>
                  </ThemeProvider>
                </Box>
              </Box>
            </Card>
          </Page>
        </form>
      )}
    </Formik>
  );
};

Change_Password.propTypes = {
  className: PropTypes.string
};

export default Change_Password;

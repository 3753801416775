import AWSAppSyncClient,{AUTH_TYPE} from 'aws-appsync'
import awsconfig from 'src/utils/aws-exports-company'
import awsconfigProd from 'src/utils/aws-exports-institution-prod'
import Cookies from 'js-cookie';


const TestAPI = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    jwtToken: async () => `${Cookies.get('idToken')}`
  },
  cacheOptions: {
    addTypename: false
  },
  disableOffline : true
});


const ProdAPI = new AWSAppSyncClient({
  url: awsconfigProd.aws_appsync_graphqlEndpoint,
  region: awsconfigProd.aws_appsync_region,
  auth: {
    type: awsconfigProd.aws_appsync_authenticationType,
    jwtToken: async () => `${Cookies.get('idToken')}`,
  },
  disableOffline : true
});


const client =process.env.REACT_APP_ENV === 'test' ? TestAPI : ProdAPI;

export default client;

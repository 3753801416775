import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const { user } = useAuth();
  const { isAuthenticated } = useAuth();
  const {message} = useAuth();
console.log(message)
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
    
  }

  if (isAuthenticated && message == "NEW_PASSWORD_REQUIRED") {
    
    return <Redirect to="/reset_password"/>;
 }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
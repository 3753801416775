import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'EXPORT': {
      const { userdata ,updatesuccessfull } = action.payload;
    
      return {
        ...state,
        userdata,
        updatesuccessfull
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const FeeSetupContext = createContext({
  ...initialCreateState,
  addprogram: () => Promise.resolve(),
  addprogram1 : () => Promise.resolve(),
  transactionReport : () => Promise.resolve(),
  transactions_upload : () => Promise.resolve(),
  orders_upload : () => Promise.resolve()
});

export const FeeSetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const addprogram = async (data  
     ) => {
         console.log("hit")
   
    try {
      let response;
      response = await  axios.post("https://feeapi.rovelabs.com/import", data );
      console.log(response)
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user : response.data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err.response.data,
          updatesuccessfull : false
        }
      });
    }
      console.log(err.message);
    }
  };

  const transactions_upload = async (data  
    ) => {
        console.log("hit")
  
        try {
          let response;
          if (process.env.REACT_APP_ENV === 'test') {
            response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_batch_transaction", data );
            }else{
             response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_batch_transaction", data );
            }
          console.log(response)
          dispatch({
            type: 'UPDATE_INSTITUTION',
            payload: {
              user : response.data,
              updatesuccessfull : true
            }
          });
        } catch (err) {
          console.log(err);
          if (err.response) {
          dispatch({
            type: 'UPDATE_INSTITUTION_ERR',
            payload: {
              err: err.response.data,
              updatesuccessfull : false
            }
          });
        }
          console.log(err.message);
        }
 };

 const orders_upload = async (data  
 ) => {
     console.log("hit")

     try {
       let response;
       if (process.env.REACT_APP_ENV === 'test') {
         response = await  axios.post("https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/fee_order", data );
         }else{
          response = await  axios.post("https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/fee_order", data );
         }
       console.log(response)
       dispatch({
         type: 'UPDATE_INSTITUTION',
         payload: {
           user : response.data,
           updatesuccessfull : true
         }
       });
     } catch (err) {
       console.log(err);
       if (err.response) {
       dispatch({
         type: 'UPDATE_INSTITUTION_ERR',
         payload: {
           err: err.response.data,
           updatesuccessfull : false
         }
       });
     }
       console.log(err.message);
     }
};


  const addprogram1 = async (data  
    ) => {
        console.log("hit")
   try {
     let response;
     response = await  axios.post("https://feeapi.rovelabs.com/importData", data );
     console.log(response)
     dispatch({
       type: 'ADD_IMPORT',
       payload: {
         user1 : response.data,
         updatesuccessfull1 : true
       }
     });
   } catch (err) {
     console.log(err);
     if (err.response) {
     dispatch({
       type: 'ADD_IMPORT_ERROR',
       payload: {
         err1: err.response.data,
         updatesuccessfull1 : false
       }
     });
   }
     console.log(err.message);
   }
 };

 const transactionReport = async (data  
  ) => {
      console.log("hit")
 try {
  let response;
  if (process.env.REACT_APP_ENV === 'test') {
  response = await  axios.post("https://slxi16x4x3.execute-api.ap-south-1.amazonaws.com/test/transaction_export", data );
  }else{
   response = await  axios.post("https://slxi16x4x3.execute-api.ap-south-1.amazonaws.com/prod/transaction_export", data );
  }
  console.log(response)
   dispatch({
     type: 'EXPORT',
     payload: {
       userdata : response.data.message,
       updatesuccessfull : true
     }
   });
 } catch (err) {
   console.log(err);
   if (err.response) {
   dispatch({
     type: 'ADD_IMPORT_ERROR',
     payload: {
       err: err.response.data,
       updatesuccessfull1 : false
     }
   });
 }
   console.log(err.message);
 }
};

  return (
    <FeeSetupContext.Provider
      value={{
        ...state,
        addprogram,
        addprogram1,
        transactionReport,
        transactions_upload,
        orders_upload
      }}
    >
      {children}
    </FeeSetupContext.Provider>
  );
};

export default FeeSetupContext;
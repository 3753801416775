import React from 'react';
import { withStyles,} from '@material-ui/core';
import {Button} from  '@material-ui/core';

const CustomButton = withStyles(theme => ({
  root: {
    color: ' #002250',
    borderColor: ' 1px solid #9e9e9e',
    textTransform: 'none',
    borderRadius: '5px',
    variant:"outlined"
  }
}))(Button);


export default CustomButton;
